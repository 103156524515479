<template>
  <div class="my-flex-container-column">
    <organizations-filter @active-change="handlerFilter" :filter-model="filterModel"
                          @clear-filters="clearFilters"/>
    <organizations-table :filter-active="filterActive" :filter-model="filterModel"/>
  </div>
</template>

<script>
import OrganizationsFilter from '@/views/Nsi/Organizations/OrganizationsFilter';
import OrganizationsTable from '@/views/Nsi/Organizations/OrganizationsTable';

export default {
  name: 'Organizations',
  components: { OrganizationsTable, OrganizationsFilter },
  data() {
    return {
      filterModel: {
        nameFilter: '',
        organizationTypeIdFilter: [],
        isLocalNsiFilter: false,
        isRuFilter: false,
      },
      filterActive: true
    };
  },
  methods: {
    handlerFilter(e) {
      this.filterActive = e;
    },
    clearFilters() {
      this.filterModel = {
        nameFilter: '',
        organizationTypeIdFilter: [],
        isLocalNsiFilter: false,
        isRuFilter: false,
      };
    }
  }
};
</script>

<style scoped>

</style>
