<template>
  <el-collapse v-model="filter" @change="handleChange">
    <el-collapse-item title="Фильтр" name="p1">
      <template v-slot:title>
        <span>
          <span>Фильтр</span>
          <el-button class="ml-5"
              plain
              size="mini"
              type="default" 
              @click.stop="clearFilters" 
              style="font-family: Montserrat-Medium !important;">
            Очистить фильтры
          </el-button>
        </span>
      </template>
      <el-row :gutter="5" class="mt-0 filter">
        <el-col :span="12" class="ml-2 filter--container">Наименование:</el-col>
        <el-col :span="6" class="ml-0 filter--container">Тип:</el-col>
        <el-col :span="6" class="ml-0 filter--container">Отображать объекты локальной НСИ:</el-col>
      </el-row>
      <el-row class="m-0 filter">
        <el-col :span="12" class="filter--container ml-2">
          <!-- <span class="mr-2">Наименование:</span> -->
          <remote-search-input
            v-model="filterModel.nameFilter"
            searchUrl="/api/organizations/SearchByName"
          />
        </el-col>
        <el-col :span="6" class="filter--container ml-2">
          <!-- <span class="mr-2">Тип:</span> -->
          <el-select multiple v-model="filterModel.organizationTypeIdFilter" clearable size="small">
            <div class="pl-3">
              <el-button type="text" size="small"
                         @click="filterModel.organizationTypeIdFilter = selectAllValues($event)">
                Выбрать всё
              </el-button>
            </div>
            <el-option
              v-for="item in organizationTypes"
              :key="item.id"
              :label="item.name"
              :value="item.name">
            </el-option>
          </el-select>
        </el-col>
        <el-col :span="6" class="filter--container ml-2">
          <!-- <span class="mr-2">Отображать объекты локальной НСИ:</span> -->
          <el-switch style="width: 100%;"
                v-model="filterModel.isLocalNsiFilter"
                active-color="#13ce66"
                inactive-color="#ff4949"
              />
        </el-col>
      </el-row>
      <!-- <el-row class="m-0 filter">
        <el-col :span="8" class="ml-2 filter--container">
          <el-button @click="clearFilters" type="text"
                     style="font-family: Montserrat-Medium !important;">
            Очистить фильтры
          </el-button>
        </el-col>
      </el-row> -->
    </el-collapse-item>
  </el-collapse>
</template>

<script>
import { mapGetters } from 'vuex';
import RemoteSearchInput from '@/components/Ui/RemoteSearchInput';
import UserSettingsService from '@/service/userSettingsService';

export default {
  name: 'OrganizationsFilter',
  props: {
    filterModel: { type: Object, default: () => {} },
  },
  components: { RemoteSearchInput },
  data() {
    return {
      filter: [''],
      isLocalNsi: false,
    };
  },
  created() {
    if (UserSettingsService.has('organizationsFilter_filter_open', this.user.id)) {
      this.filter = ['p1'];
    }
  },
  computed: {
    ...mapGetters('dictionaries', ['organizationTypes']),
    ...mapGetters('identity', ['user'])
  },
  methods: {
    handleChange(val) {
      if (val.find((el) => el === 'p1')) {
        UserSettingsService.set('organizationsFilter_filter_open', true, this.user.id);
        this.$emit('active-change', true);
      } else {
        UserSettingsService.delete('organizationsFilter_filter_open', this.user.id);
        this.$emit('active-change', false);
      }
    },
    clearFilters() {
      this.$emit('clear-filters');
    },
    selectAllValues(event /* dict */) {
      const allValues = [];
      /*
      // eslint-disable-next-line no-restricted-syntax
      for (const item of dict) {
          allValues.push(item.name);
      } */
      // временное решение, но вроде в принципе работает нормально
      const options = event.target.parentNode.parentNode.parentNode.querySelectorAll('li:not([style="display: none;"])');
      options.forEach((el) => (allValues.push(el.innerText)));

      return allValues;
    },
  }
};
</script>

<style scoped>

</style>
